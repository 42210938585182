.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: auto;
  max-height: calc(100vh - 58px);
  padding-bottom: 50px;

  .order {
    border: none;
    background-color: transparent;

    &:hover, &:focus {
      color: @fgColor;
    }
  }

  .table {
    table-layout: fixed;
    border-spacing: 5px 5px;
    border-collapse: separate;

    td, th {
      background-color: rgba(255,255,255,0.1);
      padding: 3px;
      text-align: center;
    }
    td:hover, th:hover {
      background-color: rgba(255,255,255,0.2);
    }

    tr:first-child > th {
      background-color: transparent;

      canvas {
        width: 150px;
        border: 1px solid black;
      }
    }

    .elapse {
      td, th {
        background-color: transparent;
      }
      td:hover, th:hover {
        background-color: transparent;
      }
    }

    td.current {
      background-color: rgba(255,255,255,0.4);

      &:hover {
        background-color: rgba(255,255,255,0.6);
      }
    }

    thead {
      tr:first-child>th:first-child {
        background-color: transparent;
      }
    }

    tbody {
      tr:not(.elapse):hover>td:first-child {
        background-color: rgba(255,255,255,0.2);
      }
    }

    td.empty {
      background-color: transparent;
    }
  }

  .other {
    display: flex;
    justify-content: space-around;
    gap: 50px;

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        border: solid 5px black;
        background-color: transparent;
        border-radius: 0px;
        height: 40px;
      }

      button:focus-visible {
        outline: none;
      }

      button:hover, button:focus {
        background-color: @fgColor;
        border-color: @fgColor;
        color: @bgColor;
      }
    }
  }
}
@primary-color: #2c61eb;@text-color: rgba(0,0,0,.85);@border-radius-base: 4px;@border-color-base: #2c61eb;@font-family: "Helvetica Neue", "Helvetica", sans-serif;@font-size-base: 14px;@typography-title-font-weight: 500;@bgColor: #73a582;@fgColor: #000000;