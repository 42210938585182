html, body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: @bgColor;
  font-family: nokia, Verdana, Tahoma;
}
h1, h2 {
  text-align: center;
  max-height: 88px;
}

@primary-color: #2c61eb;@text-color: rgba(0,0,0,.85);@border-radius-base: 4px;@border-color-base: #2c61eb;@font-family: "Helvetica Neue", "Helvetica", sans-serif;@font-size-base: 14px;@typography-title-font-weight: 500;@bgColor: #73a582;@fgColor: #000000;