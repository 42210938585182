
.scene {
  outline: 0px solid transparent;
  cursor: auto;
  width: 100vw;
  height: calc(100svh - 88px);
}

.mobile {
  display: flex;

  &>* {
    height: calc(100svh - 88px);
    background-color: transparent;
  }

  .button {
    flex: 1;
  }

  .scene {
    width: 60vw;
  }
}

@primary-color: #2c61eb;@text-color: rgba(0,0,0,.85);@border-radius-base: 4px;@border-color-base: #2c61eb;@font-family: "Helvetica Neue", "Helvetica", sans-serif;@font-size-base: 14px;@typography-title-font-weight: 500;@bgColor: #73a582;@fgColor: #000000;