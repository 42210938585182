:global(.ant-message-custom-content)
{
  display: flex;
  align-items: center;
  gap: 5px;

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .p h3 {
      margin: 0px;
      padding: 0px;
    }
  }
}

.pure :global(.ant-message-notice-content) {
  box-shadow: 0px 0px 5px 1px black;
  background-color: @bgColor;
}

@primary-color: #2c61eb;@text-color: rgba(0,0,0,.85);@border-radius-base: 4px;@border-color-base: #2c61eb;@font-family: "Helvetica Neue", "Helvetica", sans-serif;@font-size-base: 14px;@typography-title-font-weight: 500;@bgColor: #73a582;@fgColor: #000000;