.wrap {
  position: relative;

  &:hover {
    button {
      opacity: 1;
    }
  }
  button {
    opacity: 0;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 9px;
    right: 8px;
  }
}
@primary-color: #2c61eb;@text-color: rgba(0,0,0,.85);@border-radius-base: 4px;@border-color-base: #2c61eb;@font-family: "Helvetica Neue", "Helvetica", sans-serif;@font-size-base: 14px;@typography-title-font-weight: 500;@bgColor: #73a582;@fgColor: #000000;